import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
// https://stackoverflow.com/questions/51883593/detect-browsers-language-with-i18next-browser-languagedetector-for-reactjs-web#answer-63749016
import XHR from "i18next-http-backend";

const resources = require('../locales/translations.json');
const languages = Object.keys(resources);

const options = {
  // order: ['querystring', 'navigator'], 
  // order: ['path', 'navigator', 'querystring'], 
  order: ['path'], 
  lookupQuerystring: 'lng', // ?lng=fr
  lookupFromPathIndex: 0
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: options,
    // debug: true,
    fallbackLng: 'en',
    supportedLngs: languages,
    whitelist: languages,
    interpolation: {
      escapeValue: false // react already safe from xss
    }
  });
export default i18n;

// set language:
//i18n.changeLanguage('fr');

i18n.changeLanguage();