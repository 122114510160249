import React from 'react';
import { Link } from 'gatsby';

/**
 * Generates the button classname
 * Converts any camelcase text to dash seperated
 *
 * @param {*} str
 * @returns
 */
function classnameConverter(str) {
    return `c-button--${str.replace(/[A-Z]/g, m => '-' + m.toLowerCase())}`;
}

/**
 * Generates a string with the required css classnames
 *
 * @param {*} classnames
 * @param {*} originalClasses
 * @returns
 */
function prepareClasses(classnames, originalClasses) {
    let classes = ['c-button'];

    if (originalClasses) classes.push(originalClasses);

    Object.keys(classnames).map(key => {
        if (classnames[key]) {
            return classes.push(classnameConverter(key));
        }
        return false;
    });

    classes = classes.join(' ');

    return classes;
}

const Button = ({ className, text, large, small, black, outlineBlack, outlineAllWhite, autoWidth, disabledLink, link, ...rest }) => {
    let classes = prepareClasses({ large, small, black, outlineBlack, outlineAllWhite, autoWidth, disabledLink }, className);

    if (link) {
        return (
            <Link className={classes} {...rest}>
                <span className="c-button__text">{text}</span>
            </Link>
        );
    }

    return (
        <button className={classes} {...rest}>
            <span className="c-button__text">{text}</span>
        </button>
    );
};

export default Button;