import React, { useRef }  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Logo from '../assets/images/wintech-racing.svg';
import TopLinks from './component.topLinks';
import Nav from './nav/component.nav';
import NavQuickLinks from './nav/component.nav-quicklinks';

const resources = require('../locales/translations.json');
const languages = Object.keys(resources);

const Header = ({ siteTitle, language, translations }) => {
    const headerEl = useRef(null);

    let i, targetURL, currentLanguage, pageLanguage;

    let translatedURLs = [];
    
    languages.map(item => {
        currentLanguage = false;
        pageLanguage = "en";
        if(typeof language !== "undefined") {
            pageLanguage = language.toLowerCase();
        }
        
        targetURL = (item == "en" ? "/" : "/" + item + "/");
        for (i in translations) {
            if(translations[i].language.code.toLowerCase() == item) {
                // use the translated URL:
                    targetURL = translations[i].uri.replace("/products/", "/").replace("/product_categories/", "/");
                break;
            }
        }
        if(pageLanguage == item){
            currentLanguage = true;
        }
        translatedURLs.push({url: targetURL, code: item, active: currentLanguage});
    });
    
    return (
        <>
            <header ref={headerEl} className="c-header">
                <TopLinks translatedURLs={translatedURLs} language={language} />
        
                <div className="c-header__main">
                    <div className="container">
                        <div className="c-header__items">
                            <Link className="c-header__logo" to={pageLanguage == "en" ? "/" : "/" + pageLanguage + "/"}>
                                <img src={Logo} alt="Wintech Racing"/>
                            </Link>
                            <Nav headerHeight={headerEl} language={language} />
                        </div>
                    </div>
                </div>
            </header>
            <NavQuickLinks language={language} />
        </>
    );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
