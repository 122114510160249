import React, { useContext } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { withTranslation } from 'react-i18next';

//import '../component.i18n';

import ThemeContext from '../../context/context.theme';
import Button from '../component.button';

const NavSub = ({ images, links, subTitle, active, headerHeight, back, topLevelTitle, topLevelSlug, t, i18n, language }) => {
    const theme = useContext(ThemeContext);
    const defaultMenuImage = useStaticQuery(graphql`
        query defaultMenuImage {
            file(relativePath: { eq: "default-post-image.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 253, height: 118) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    // Used to position the submenu to the top of the screen or below the header dependent on the resolution
    let top = 0;

      let pageLanguage = "en";
      if(typeof language !== "undefined") {
          pageLanguage = language.toLowerCase();
      }


    if (theme.screenType === 'desktop') {
        top = headerHeight && headerHeight.current && headerHeight.current.clientHeight;
    }

    return (
        <div style={{ top }} className={active ? "c-nav__sub c-nav__sub--active" : "c-nav__sub"} aria-hidden={!active}>
            <div className="container">
                <a className="c-nav__back" onClick={back}>
                    <i className="fal fa-chevron-left"></i> {t("nav.nav-sub.back-label")}
                </a>

                <div className="row">
                    <div className="col-lg-9">
                        <div className="c-nav__sub-section">
                            <ul className="c-nav__image-list">
                                {images && images.map((imageItem, index) => (
                                    <li key={index} className="c-nav__image-item">
                                        <Link to={imageItem.link.url} tabIndex="-1" className="c-nav__image-link" onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                            <Img
                                                className="c-nav__image-image"
                                                fixed={imageItem.image ? imageItem.image.imageFile.childImageSharp.thumbnail : defaultMenuImage.file.childImageSharp.thumbnail}
                                                alt={imageItem.image ? imageItem.image.altText : t("nav.nav-sub.image-alt")}
                                            />
                                            <span className="c-nav__image-title">{imageItem.title}</span>
                                        </Link>
                                    </li>
                                ))}
                                {topLevelTitle && (
                                    <li key={topLevelSlug} className="c-nav__all">
                                        <Link className="c-nav__all-link" to={topLevelSlug} onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                            {`${t("nav.nav-sub.link-label")} ${topLevelTitle}`} <i className="c-nav__all-icon fal fa-chevron-right"></i>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="c-nav__sub-section">
                            <span className="c-nav__title" dangerouslySetInnerHTML={{ __html: subTitle }}></span>

                            <ul className="c-nav__text-list">
                                {links && links.map((link, index) => {
                                    // Provides a fallback if a link URL/slug has not been set
                                    const slug = link.link ? link.link.url : '';

                                    return (
                                        <li key={index} className="c-nav__text-item">
                                            <Link to={slug} tabIndex="-1" className="c-nav__text-link" onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                                {link.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Button className="c-nav__text-button" link to={(pageLanguage == "en" ? "/build-your-fleet/" : "/" + pageLanguage.toLowerCase()+"/build-your-fleet/")} text={t("nav.nav-sub.button-label")} onClick={theme.screenType === "mobile" ? theme.toggleMenu : null} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(NavSub);
