import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { withTranslation } from 'react-i18next';

// withTranslation injects the i18n object, so no need:
//import './component.i18n';



const Footer = ({ t, i18n, language }) => {
    const footerData = useStaticQuery(graphql`


        fragment footerContent on WORDPRESS_GlobalContent {
            ACFFooterBlockFields {
                content
                title
                discoverLinks {
                    link {
                        url
                        title
                    }
                }
                helpLinks {
                    link {
                        url
                        title
                    }
                }
                productLinks {
                    link {
                        url
                        title
                    }
                }
                technologyLinks {
                    link {
                        url
                        title
                    }
                }
                socialMediaLinks {
                    icon
                    link {
                        url
                    }
                }
                privacyLinks {
                    link {
                        url
                        title
                    }
                }
            }
        }
        query footer {
            wordpress {
                EN: globalContent(idType: SLUG, id: "footer-en") {
                    ...footerContent
                }
                FR: globalContent(idType: SLUG, id: "footer-fr") {
                    ...footerContent
                }
                ES: globalContent(idType: SLUG, id: "footer-es") {
                    ...footerContent
                }
                ZH: globalContent(idType: SLUG, id: "footer-zh") {
                    ...footerContent
                }
                DE: globalContent(idType: SLUG, id: "footer-de") {
                    ...footerContent
                }
                PL: globalContent(idType: SLUG, id: "footer-pl") {
                    ...footerContent
                }
            }
        }
    `);

    let content;
    // language is taken from the page's language code
    if(typeof footerData.wordpress[language] !== "undefined") {
        content = footerData.wordpress[language].ACFFooterBlockFields;
    } else {
        content = footerData.wordpress['EN'].ACFFooterBlockFields;
    } 

    return (
        <footer className="c-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <span className="d-block u-text--bold xs-mb-10">{content.title}</span>
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>

                        <ul className="c-social-share">
                            {content.socialMediaLinks.map(({ link, icon }, index) => (
                                <li key={index} className="c-social-share__item">
                                    <a className="c-social-share__link" href={link.url} aria-label={`View our ${icon}`}>
                                        <i className={`c-social-share__icon fab fa-${icon}`}></i>
                                    </a>
                                </li>
                            ))}
                        </ul>

                        <hr className="xs-mb-30 xs-mt-30 d-lg-none d-xl-none" />
                    </div>

                    <div className="offset-lg-1 col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">{t('footer.heading-products')}</h4>
                            <ul className="c-sub-nav__list">
                                {content.productLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={(i18n.language == "en" ? link.url : "/" + i18n.language.toLowerCase()+link.url)}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">{t('footer.heading-technology')}</h4>
                            <ul className="c-sub-nav__list">
                                {content.technologyLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={(i18n.language == "en" ? link.url : "/" + i18n.language.toLowerCase()+link.url)}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">{t('footer.heading-help')}</h4>
                            <ul className="c-sub-nav__list">
                                {content.helpLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={(i18n.language == "en" ? link.url : "/" + i18n.language.toLowerCase()+link.url)}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">{t('footer.heading-discover')}</h4>
                            <ul className="c-sub-nav__list">
                                {content.discoverLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={link.url || "/"}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="c-bottom-links">
                    <span className="c-bottom-links__copyright">
                        &copy; {new Date().getFullYear()} {t('footer.copyright')} <a href="https://www.absolute-design.co.uk" target="_blank" rel="noopener noreferrer">{t('footer.website-by')} Absolute</a>
                    </span>
                    <ul className="c-bottom-links__list">
                        {content.privacyLinks.map(({ link }) => {
                            if (link) {
                                return (
                                    <li key={link.title} className="c-bottom-links__item">
                                        <Link className="c-bottom-links__link" to={link.url || "/"}>
                                            {link.title}
                                        </Link>
                                    </li>
                                );
                            }
                            return false;
                        })}
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default withTranslation()(Footer);