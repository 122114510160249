import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import { withTranslation } from 'react-i18next';

const Search = ({ headerHeight, t, language }) => {
    const searchToggle = useRef(null);
    const searchEl = useRef(null);
    const searchInput = useRef(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);

    // Used to position the search below the header
    const [top, setTop] = useState(false);

    // If search is open add a listner for external clicks to trigger close
    useEffect(() => {
        setTop(headerHeight && headerHeight.current && headerHeight.current.clientHeight);

        if (showSearch) {
            document.addEventListener('mousedown', handleClickOutside);
            searchInput.current.focus();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSearch, headerHeight]);

    const handleClickOutside = (e) => {
        if (searchEl.current.contains(e.target) || searchToggle.current.contains(e.target)) {
            // Click inside search
            return false;
        }

        setShowSearch(false);
    };

    let languageCode = "EN";
    if (typeof language !== "undefined") {
        languageCode = language;
    }
    let searchURL = (languageCode == "EN" ? '/search' : '/'+languageCode.toLowerCase()+'/search');
    if(languageCode == "ZH") {
        searchURL = '/zh/sou-suo';
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(searchURL, {
            state: { searchQuery },
        });
    };

    /**
     * Update search state variable and focus element
     *
     */
    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };

    return (
        <>
            <button
                className={showSearch ? "c-search-toggle c-search-toggle--active" : "c-search-toggle"}
                onClick={toggleSearch}
                aria-label="Toggle Search"
            >
                <i
                    ref={searchToggle}
                    className={showSearch ? "fal fa-times" : "far fa-search"}
                >
                </i>
            </button>

            <div ref={searchEl} style={{ top }} className={showSearch ? "c-search c-search--active" : "c-search"} aria-hidden={!showSearch}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="c-search__inner">
                                    <input
                                        ref={searchInput}
                                        aria-label={t("search.placeholder")}
                                        className="c-search__input"
                                        placeholder={`${t("search.placeholder")}...`}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <button className="c-search__icon far fa-search"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(Search);
