import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

let id = 0;

const Notifications = ({ config = { tension: 125, friction: 20, precision: 0.1 }, timeout = 3000, children }) => {
    const [refMap] = useState(() => new WeakMap());
    const [cancelMap] = useState(() => new WeakMap());
    const [items, setItems] = useState([]);
    const transitions = useTransition(items, item => item.key, {
        from: { opacity: 0, height: 0, life: '100%' },
        enter: item => async next => await next({ opacity: 1, height: refMap.get(item).offsetHeight }),
        leave: item => async (next, cancel) => {
            cancelMap.set(item, cancel);
            await next({ life: '0%' });
            await next({ opacity: 0 });
            await next({ height: 0 });
        },
        onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
        config: (item, state) => (state === 'leave' ? [{ duration: timeout }, config, config] : config),
    });

    useEffect(() => void children(({ message, success }) => setItems(state => [...state, { key: id++, message, success }])), []);

    return (
        <div className="c-notifications">
            {transitions.map(({ key, item, props: { life, ...style } }) => (
                <animated.div className="c-notifications__message" key={key} style={style}>
                    <div className="c-notifications__content" ref={ref => ref && refMap.set(item, ref)}>
                        <animated.div className="c-notifications__life" style={{ right: life }} />
                        <p className={item.success === true ? "c-notifications__description c-notifications__description--success" : "c-notifications__description c-notifications__description--error"} dangerouslySetInnerHTML={{ __html: item.message }}></p>
                        <button
                            aria-label="close"
                            className="c-notifications__button"
                            onClick={e => {
                                e.stopPropagation();
                                cancelMap.has(item) && cancelMap.get(item)();
                            }}
                        >
                            <i className="fal fa-times"></i>
                        </button>
                    </div>
                </animated.div>
            ))}
        </div>
    );
}

export default Notifications;
