import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import { Link } from 'gatsby';
import { withTranslation } from 'react-i18next';
import Button from './component.button';

import flagCS from '../assets/images/flags/cs.svg';
import flagDE from '../assets/images/flags/de.svg';
import flagEN from '../assets/images/flags/en.svg';
import flagES from '../assets/images/flags/es.svg';
import flagFR from '../assets/images/flags/fr.svg';
import flagZH from '../assets/images/flags/zh.svg';
import flagPL from '../assets/images/flags/pl.svg';

// Bind modal to the appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

// \node_modules\i18next-browser-languagedetector\dist\cjs\i18nextBrowserLanguageDetector.js
function getNavigatorLanguage() {
  var found = [];
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (var i = 0; i < navigator.languages.length; i++) {
        found.push(navigator.languages[i]);
      }
    }
    if (navigator.userLanguage) {
      found.push(navigator.userLanguage);
    }
    if (navigator.language) {
      found.push(navigator.language);
    }
  }
  //return found.length > 0 ? found : undefined;
  return found.length > 0 ? found[0].split("-")[0].toLowerCase() : undefined;
}

const usersLanguage = getNavigatorLanguage();





function getNameAndFlag(langCode) {
  let languageName, languageFlag, modalTitle, modalButton, modalSubTitle;
  switch(langCode) {
    case "en":
      languageName = "English";
      languageFlag = flagEN;
      modalTitle = "Hi there, looks like you might like to view this in English";
      modalButton = "View in English";
      modalSubTitle = "Or maybe another language?";
    break;
    case "fr":
      languageName = "Français";
      languageFlag = flagFR;
      modalTitle = "Hi there, looks like you might like to view this in Français";
      modalButton = "View in Français";
      modalSubTitle = "Or maybe another language?";
    break;
    case "pl":
      languageName = "Polski";
      languageFlag = flagPL;
      modalTitle = "Cześć, wygląda na to, że chciałbyś zobaczyć to w Polski";
      modalButton = "Zobacz w języku polskim";
      modalSubTitle = "A może inny język?";
    break;
    case "es":
      languageName = "Español";
      languageFlag = flagES;
      modalTitle = "Hola, parece que te gustaría ver esto en Español";
      modalButton = "Ver en Español";
      modalSubTitle = "¿O quizás en otro idioma?";
    break;
    case "de":
      languageName = "Deutsch";
      languageFlag = flagDE;
      modalTitle = "Hi there, looks like you might like to view this in Deutsch";
      modalButton = "View in Deutsch";
      modalSubTitle = "Or maybe another language?";
    break;
    case "cs":
      languageName = "Čeština";
      languageFlag = flagCS;
      modalTitle = "Hi there, looks like you might like to view this in Čeština";
      modalButton = "View in Čeština";
      modalSubTitle = "Or maybe another language?";
    break;
    case "zh":
      languageName = "中文 (中国)";
      languageFlag = flagZH;
      modalTitle = "Hi there, looks like you might like to view this in 中文 (中国)";
      modalButton = "View in 中文 (中国)";
      modalSubTitle = "Or maybe another language?";
    break;
  }
  return [languageName, languageFlag, modalTitle, modalButton, modalSubTitle];
}

const usersLanguageDetails = getNameAndFlag(usersLanguage);


const Popup = ({ t, i18n, language, translations }) => {
  
const [modalIsOpen, setModalIsOpen] = useState(false);

const resources = require('../locales/translations.json');
const languages = Object.keys(resources);


  let pageLanguage = "en";
  let i, targetURL, pageDefaultTargetURL;
  if(typeof language !== "undefined") {
      pageLanguage = language.toLowerCase();
  }
  // set default:
  pageDefaultTargetURL = (pageLanguage == "en" ? '/' : '/'+pageLanguage+'/');
  for (i in translations) {
    if(translations[i].language.code.toLowerCase() == usersLanguage) {
        // use the translated URL:
        pageDefaultTargetURL = translations[i].uri.replace("/products/", "/").replace("/product_categories/", "/");
        break;
    }
}


  useEffect(()=>{
    if(usersLanguage != pageLanguage) {
      let lngSelection = localStorage.getItem('lngSelection');
      if(!lngSelection){
        setModalIsOpen(true);
        localStorage.setItem('lngSelection',true);
      }
    }
  },[]);

  function closeModal() {
    setModalIsOpen(false);
  }

  let translatedURLs = [];
    
  languages.map(item => {
      pageLanguage = "en";
      if(typeof language !== "undefined") {
          pageLanguage = language.toLowerCase();
      }
      
      targetURL = (item == "en" ? "/" : "/" + item + "/");
      for (i in translations) {
          if(translations[i].language.code.toLowerCase() == item) {
              // use the translated URL:
                  if(translations[i].language.code == "EN") {
                      targetURL = translations[i].uri;
                  } else {
                      targetURL = translations[i].uri.replace("/products/", "/").replace("/product_categories/", "/");
                  }
              break;
          }
      }
      // don't add the current language 
      if(item != pageLanguage) {
        // don't add the primary recommended language again:
        if(item != usersLanguage) {
          translatedURLs.push({url: targetURL, code: item, flag: getNameAndFlag(item)[1], name: getNameAndFlag(item)[0]});
        }
      }
      
  });

  return (
    <Modal
        className={'c-modal c-modal--no-frame c-modal--language'}
        overlayClassName="c-modal__overlay"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
    >

      <div className="c-modal__content c-modal__content--language">

        <div className="c-modal__head">
          <button className='c-button c-button--close' onClick={closeModal} aria-label={t("product.enquiry.close-label")}><i className="fal fa-times"></i></button>
        </div>

        <div className="c-modal__wrapper">
          <div className="c-modal__main">
            <img src={usersLanguageDetails[1]} alt={usersLanguageDetails[0]} />
            <h1>{usersLanguageDetails[2]}</h1>
            <Link className="c-button" to={pageDefaultTargetURL}>
              <span className="c-button__text">{usersLanguageDetails[3]}</span>
            </Link>
            
          </div>
          <div className="c-modal__sub">
            <h2>{usersLanguageDetails[4]}</h2>
            <ul className="c-modal__sublist">
               {translatedURLs.map((item, index) => (
                  <li className="c-modal__subitem">
                      <Link className="c-modal__sublink" to={item.url}><span>{item.name}</span><img src={item.flag} alt={item.code} /></Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  )
  
};

export default withTranslation()(Popup);