import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { withTranslation } from 'react-i18next';

let topLinkClass = 'u-bg--dove-grey';
if(process.env.GATSBY_THEME == "King") {
    topLinkClass = 'u-bg--primary';
}

import kingLogoWhite from '../assets/images/king-racing-shells-white.svg';
import kingLogoBlack from '../assets/images/king-racing-shells.svg';
import wintechLogoWhite from '../assets/images/wintech-racing-white.svg';
import wintechLogoBlack from '../assets/images/wintech-racing-black.svg';

import flagCS from '../assets/images/flags/cs.svg';
import flagDE from '../assets/images/flags/de.svg';
import flagEN from '../assets/images/flags/en.svg';
import flagES from '../assets/images/flags/es.svg';
import flagFR from '../assets/images/flags/fr.svg';
import flagZH from '../assets/images/flags/zh.svg';
import flagPL from '../assets/images/flags/pl.svg';

const allFlags = {
    cs: flagCS,
    de: flagDE,
    en: flagEN,
    es: flagES,
    fr: flagFR,
    zh: flagZH,
    pl: flagPL
}

const TopLinks = ({ t, i18n, language, translatedURLs }) => {

    const [languageOpen, setLanguageOpen] = useState(false);

    const topLinkData = useStaticQuery(graphql`
        fragment TopLinkContent on WORDPRESS_GlobalContent {
          ACFTopLinksBlockFields {
              topLinks {
                link {
                  title
                  url
                }
              }
            }
        }
        query toplinks {
            wordpress {
                EN: globalContent(idType: SLUG, id: "toplink-en") {
                    ...TopLinkContent
                }
                ES: globalContent(idType: SLUG, id: "toplink-es") {
                    ...TopLinkContent
                }
                FR: globalContent(idType: SLUG, id: "toplink-fr") {
                    ...TopLinkContent
                }
                ZH: globalContent(idType: SLUG, id: "toplink-zh") {
                    ...TopLinkContent
                }
                DE: globalContent(idType: SLUG, id: "toplink-de") {
                    ...TopLinkContent
                }
                PL: globalContent(idType: SLUG, id: "toplink-pl") {
                    ...TopLinkContent
                }
            }
        }
    `);

    let content;
    // language is taken from the page's language code
    if(typeof topLinkData.wordpress[language] !== "undefined") {
        content = topLinkData.wordpress[language].ACFTopLinksBlockFields.topLinks[0];
    } else {
        content = topLinkData.wordpress['EN'].ACFTopLinksBlockFields.topLinks[0];
    }

    function setModalSeen() {
        // language selector clicked, so don't show the language selection modal:
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('lngSelection',true);
        }
    }

return (

   <div className={topLinkClass}>
        <div className="container">
            <div className="c-top-links">

                <div className="c-top-links__lang">
                    {process.env.GATSBY_THEME != "King" && 
                        <button className={languageOpen ? "c-top-links__lang-switch c-top-links__lang-switch--active" : "c-top-links__lang-switch"} onClick={() => setLanguageOpen(!languageOpen)}><img src={`${allFlags[language.toLowerCase()]}`} alt={language} /></button>
                    }

                    <ul className={languageOpen ? "c-top-links__list c-top-links__lnglist c-top-links__lnglist--active" : "c-top-links__lnglist"}>
                         {translatedURLs.map((item, index) => (
                            <li className="c-top-links__item c-top-links__language">
                                <Link className={'c-top-links__link c-top-links__' + item.code + (item.active === true ? ' c-top-links__active' : '')} to={item.url} onClick={setModalSeen}><img src={`${allFlags[item.code]}`} alt={item.code} /></Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <ul className="c-top-links__switcher">
                    <li className={(process.env.GATSBY_THEME != "King" ? 'active' : '') + ' switcher-wintech'}><a href={(process.env.GATSBY_THEME != "King" ? '/' : 'https://wintechracing.com/')}><img src={(process.env.GATSBY_THEME == "King" ? wintechLogoWhite : wintechLogoBlack)} alt="WinTech Racing"/></a></li>
                    <li className={(process.env.GATSBY_THEME == "King" ? 'active' : '') + ' switcher-king'}><a href={(process.env.GATSBY_THEME == "King" ? '/' : 'https://kingracingshells.com/')}><img src={(process.env.GATSBY_THEME == "King" ? kingLogoBlack : kingLogoWhite)} alt="King Racing"/></a></li>
                </ul>

                <ul className="c-top-links__list">
                    <li className="c-top-links__item">
                        <Link className="c-top-links__link" to={content.link.url}>{content.link.title}</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    );
}

export default withTranslation()(TopLinks);