import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { withTranslation } from 'react-i18next';

const NavQuickLinks = ({ t, i18n, language }) => {
    
    const quickLinksData = useStaticQuery(graphql`
        fragment navigationQuickContent on WORDPRESS_GlobalContent {
            ACFquickLinksBlockFields {
                quickLinks {
                  fontAwesomeIcon
                  pageLink {
                    title
                    url
                  }
                }
            }
        }

        query QuickLinkMenu {
            wordpress {
                EN: globalContents(where: {title: "Quick Links - EN"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
                FR: globalContents(where: {title: "Quick Links - FR"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
                ES: globalContents(where: {title: "Quick Links - ES"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
                ZH: globalContents(where: {title: "Quick Links - ZH"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
                DE: globalContents(where: {title: "Quick Links - DE"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
                PL: globalContents(where: {title: "Quick Links - PL"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
            }
        }
    `);


    let menu;

    // language is taken from the page's language code
    if(typeof quickLinksData.wordpress[language] !== "undefined") {
        menu = quickLinksData.wordpress[language].nodes[0].ACFquickLinksBlockFields.quickLinks;
    } else {
        menu = quickLinksData.wordpress['EN'].nodes[0].ACFquickLinksBlockFields.quickLinks;
    } 

    return (
        <nav className="c-quick-nav" aria-label={t("nav.nav.nav-quick-label")}>
            <ul aria-hidden="false">
                {menu.map(({ ...menuItem }, index) => {
                    return (
                        <li key={index}>
                                <Link
                                    className="c-quick-nav__link"
                                    to={menuItem.pageLink.url}
                                >
                                    <i className={"c-quick-nav__icon far " + menuItem.fontAwesomeIcon}></i>
                                    <span>{menuItem.pageLink.title}</span>
                                </Link>

                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default withTranslation()(NavQuickLinks);
